import React from "react"

import Endpoint from "../Endpoint"
import ProductTypesContainer from "./ProductTypesContainer"
import {
  authCategories,
  transformAuthData,
  transactionsLiveCategories,
  transformTransactionsData,
  transformRawTransactionsData,
  transformTransactionsExpandData,
  transactionsExpandCategories,
  transformTransactionsRefreshData,
  transactionsRawCategories,
  transactionsRefreshCategories,
  accountCategories,
  transformAccountData,
  transformBalanceData,
  balanceCategories,
  onSuccessRefreshCategories,
  insightsCategories,
  insightsRefreshCategories,
  activityRefreshCategories,
  paymentRefreshCategories,
  webhooksRefreshCategories,
  loansRefreshCategories,
  loansEventsRefreshCategories,
  transformGenericData,
  loansApplicationCategories,
  transformLoanApplicationData,
} from "../../dataUtilities"

const Products = () => {
  return (
    <ProductTypesContainer productType="Products">
      <h4 className="header-second">Insights</h4>
      <Endpoint
        endpoint="insights"
        name="Insights"
        categories={insightsCategories}
        schema="/insights/"
        description="Lists all insights for the user."
        transformData={transformGenericData}
        method="GET"
      />
      <Endpoint
        endpoint="insights-archived"
        name="Archived Insights"
        categories={insightsRefreshCategories}
        schema="/insights/archived"
        description="Lists all archived insights for the user."
        transformData={transformGenericData}
        method="GET"
      />
      <Endpoint
        endpoint="only-success"
        name="Insights Take Action"
        categories={onSuccessRefreshCategories}
        schema="/insights/action"
        description="Report a user action on an insight and move the insight to an archived state."
        transformData={transformGenericData}
        method="POST"
      />
      <Endpoint
        endpoint="activity"
        name="Activity"
        categories={activityRefreshCategories}
        schema="/activity/"
        description="Retrieve activity for the user."
        transformData={transformGenericData}
        method="GET"
      />
      <h4 className="header-second">Transactions</h4>
      <Endpoint
        endpoint="transactions"
        name="Raw Transactions"
        categories={transactionsRawCategories}
        schema="/transactions/raw"
        description="Retrieve unprocessed transactions for credit and depository accounts."
        transformData={transformRawTransactionsData}
        method="GET"
      />
      <Endpoint
        endpoint="transactions"
        name="Transactions"
        categories={transactionsLiveCategories}
        schema="/transactions/"
        description="Retrieve transactions for credit and depository accounts."
        transformData={transformTransactionsData}
        method="GET"
      />
      <Endpoint
        endpoint="transactions-refresh"
        name="Refresh Transactions"
        categories={transactionsRefreshCategories}
        schema="/transactions/refresh/"
        description="Starts refresh transactions data flow."
        transformData={transformTransactionsRefreshData}
        method="GET"
      />
      <Endpoint
        endpoint="transactions-expand"
        name="Expand Transactions"
        categories={transactionsExpandCategories}
        schema="/transactions/{id}/"
        description="Retrieve single transaction for credit and depository accounts."
        transformData={transformTransactionsExpandData}
        method="GET"
      />
      <Endpoint
        endpoint="transactions-similar"
        name="Similar Transactions"
        categories={transactionsLiveCategories}
        schema="/transactions/{id}/similar/"
        description="Retrieves similar transactions for a transaction."
        transformData={transformTransactionsData}
        method="GET"
      />
      <Endpoint
        endpoint="transactions-similar"
        name="Transactions Search"
        categories={transactionsLiveCategories}
        schema="/transactions/category/{category}"
        description="Retrieve transactions with the same category."
        transformData={transformTransactionsData}
        method="GET"
      />
      <Endpoint
        endpoint="only-success"
        name="Re-categorize Transactions"
        categories={onSuccessRefreshCategories}
        schema="/transactions/recategorize"
        description="Changes category of the supplied list of transactions to the supplied category."
        transformData={transformGenericData}
        method="PUT"
      />
      <Endpoint
        endpoint="transactions-uncategorized"
        name="Uncategorized Transactions"
        categories={transactionsLiveCategories}
        schema="/transactions/uncategorized/"
        description="Retrieve transactions with category 'unknown' for categorizing."
        transformData={transformTransactionsRefreshData}
        method="GET"
      />
      <Endpoint
        endpoint="only-success"
        name="Link Transactions"
        categories={onSuccessRefreshCategories}
        schema="/transactions/link/"
        description="Link transactions, creating a transaction part for each transaction and netting out the amounts"
        transformData={transformGenericData}
        method="POST"
      />
      <Endpoint
        endpoint="only-success"
        name="Update Transactions Link"
        categories={onSuccessRefreshCategories}
        schema="/transactions/link/{linkId}"
        description="Updates a link between transactions."
        transformData={transformGenericData}
        method="PUT"
      />
      <Endpoint
        endpoint="only-success"
        name="Delete Transactions Link"
        categories={onSuccessRefreshCategories}
        schema="/transactions/link/{linkId}"
        description="Deletes a link between transactions."
        transformData={transformGenericData}
        method="DELETE"
      />
      <h4 className="header-second">Accounts</h4>
      <Endpoint
        endpoint="accounts"
        name="Accounts"
        categories={accountCategories}
        schema="/accounts/"
        description="Retrieve account and routing numbers for checking and savings accounts."
        transformData={transformAccountData}
        method="GET"
      />
      <Endpoint
        endpoint="accounts-live"
        name="Accounts Live"
        categories={accountCategories}
        schema="/accounts/live/"
        description="Retrieve live account and routing numbers for checking and savings accounts."
        transformData={transformAccountData}
        method="GET"
      />
      <Endpoint
        endpoint="accounts"
        name="Balance"
        categories={balanceCategories}
        schema="/accounts/balance/"
        description="Retrieve balances for the users accounts."
        transformData={transformBalanceData}
        method="GET"
      />
      <h4 className="header-second">Webhooks</h4>
      <Endpoint
        endpoint="webhook-list"
        name="Webhooks"
        categories={webhooksRefreshCategories}
        schema="/webhook/"
        description="List the registered webhooks for the given user."
        transformData={transformGenericData}
        method="GET"
      />
      <Endpoint
        endpoint="only-success"
        name="Create Webhook"
        categories={onSuccessRefreshCategories}
        schema="/webhook/"
        description="Create a new webhook for the user, giving the possibility to get pushed updates for certain events."
        transformData={transformGenericData}
        method="POST"
      />
      <Endpoint
        endpoint="only-success"
        name="Update given Webhook"
        categories={onSuccessRefreshCategories}
        schema="/webhook/"
        description="Update webhooks for the user based on webhooks id list."
        transformData={transformGenericData}
        method="PUT"
      />
      <Endpoint
        endpoint="only-success"
        name="Delete Webhooks"
        categories={onSuccessRefreshCategories}
        schema="/webhook/"
        description="Delete webhooks for the user based on webhooks id list."
        transformData={transformGenericData}
        method="DELETE"
      />
      <h4 className="header-second">Access Token</h4>
      <Endpoint
        endpoint="access-token"
        name="Auth"
        categories={authCategories}
        schema="/auth/access-token/"
        description="Retrieve account and routing numbers for checking and savings accounts."
        transformData={transformAuthData}
        method="GET"
      />
      <h4 className="header-second">Loans</h4>
      <Endpoint
        endpoint="get-fico-process"
        name="Loans Application"
        categories={loansApplicationCategories}
        schema="/loans/application"
        description="Lists for all loans application."
        transformData={transformLoanApplicationData}
        method="GET"
      />
      <Endpoint
        endpoint="loans"
        name="Loans"
        categories={loansRefreshCategories}
        schema="/loans/"
        description="Get all the loans information for a user."
        transformData={transformGenericData}
        method="GET"
        comingSoon
      />
      <Endpoint
        endpoint="loans-events"
        name="Loans Events"
        categories={loansEventsRefreshCategories}
        schema="/loans/events"
        description="Lists events that affect the properties of a loan such as interest rate changes."
        transformData={transformGenericData}
        method="GET"
        comingSoon
      />
      <Endpoint
        endpoint="loans-history"
        name="Loans History"
        categories={transactionsRefreshCategories}
        schema="/loans/"
        description="Lists historical rates and balances for all loans."
        transformData={transformGenericData}
        method="GET"
        comingSoon
      />
      <h4 className="header-second">Payments</h4>
      <Endpoint
        endpoint="only-success"
        name="Payment Create"
        categories={onSuccessRefreshCategories}
        schema="/payments/"
        description="Create a payment request with the given values."
        transformData={transformGenericData}
        method="POST"
        comingSoon
      />
      <Endpoint
        endpoint="payment"
        name="Payment"
        categories={paymentRefreshCategories}
        schema="/payments/"
        description="Returns the payment request data."
        transformData={transformGenericData}
        method="GET"
        comingSoon
      />
      <Endpoint
        endpoint="payment-transfers"
        name="Payment Transfers"
        categories={paymentRefreshCategories}
        schema="/payments/{paymentId}/transfers"
        description="Gets a list of bank transfers for a given payment request."
        transformData={transformGenericData}
        method="GET"
        comingSoon
      />
    </ProductTypesContainer>
  )
}

Products.displayName = "Products"

export default Products
