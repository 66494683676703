import React from "react";

import "./identity.css";

const Identity = (props) => {
  const identityHeaders = props.categories.map((category, index) => (
    <span key={index} className="identityHeader">
      {category.title}
    </span>
  ));

  const identityRows = props.data.map((item, index) => (
    <div key={index} className="identityDataRow">
      {props.categories.map((category, index) => (
        <span key={index} className="identityDataField">
          {item[category.field]}
        </span>
      ))}
    </div>
  ));

  return (
    <div className="identityTable">
      <div className="identityHeadersRow">{identityHeaders}</div>
      <div className="identityDataBody">{identityRows}</div>
    </div>
  );
};

Identity.displayName = "Identity";

export default Identity;
