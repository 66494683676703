/* eslint-disable implicit-arrow-linebreak */
import React from 'react'
import {
  List,
  ListItem,
  makeStyles,
} from '@material-ui/core'

const useStyles = makeStyles(({ spacing, palette }) => ({
  namesContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  list: {
    width: '100%',
  },
  container: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  listItem: {
    display: 'flex',
    direction: ({ isRtl }) => (isRtl ? 'rtl' : 'ltr'),
    flexDirection: 'column',
    opacity: 0,
    animation: '$fade-up 0.25s ease-in',
    animationFillMode: 'forwards',
  },
  listIndex: {
    marginRight: ({ isRtl }) => (isRtl ? 0 : spacing(2)),
    marginLeft: ({ isRtl }) => (isRtl ? spacing(2) : 0),
    display: 'inline-grid',
    width: 50,
    height: 50,
    alignItems: 'center',
    justifyItems: 'center',
    background: palette.grey[400],
    borderRadius: '50%',
    fontSize: 16,
  },
  bankText: {
    fontWeight: 500,
    display: 'inline-flex',
    fontSize: 19,
    margin: spacing(),
    marginLeft: 0,
    marginRight: 0,
  },
  line: {
    width: 50,
    height: 50,
    borderRadius: '50%',
  },
  site: {
    marginBottom: spacing(),
    display: 'inline-flex',
    color: 'rgb(200, 200, 200)',
  },
  '@keyframes fade-up': {
    from: {
      left: -20
    },
    to: {
      opacity: 1,
      left: 0,
    },
  },
}), { name: 'Banks' })

export default function Banks(props) {
  const classes = useStyles(props)
  const {
    banks,
    isButton = true,
    onClick = () => { },
  } = props

  return (
    <>
      <List aria-label="banks list" className={classes.list}>
        {banks.map(({
          image, bankId, _id, site,
        }, idx) => (
          <ListItem
            key={_id}
            button={isButton}
            onClick={() => onClick(bankId, _id)}
            className={classes.listItem}
            style={{ animationDelay: `${idx * 100}ms` }}
          >
            <div className={classes.container}>
              <div
                className={classes.listIndex}
              >
                <div
                  className={classes.line}
                  role="button"
                  tabIndex={0}
                  style={{
                    backgroundImage: `url(${image})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                  }}
                  alt="grid-button"
                />
              </div>
              <div className={classes.namesContainer}>
                <div
                  className={classes.bankText}
                  aria-selected={!isButton}
                >
                  {bankId}
                </div>
                <div className={classes.site}>
                  {site}
                </div>
              </div>
            </div>
          </ListItem>
        ))}
      </List>
    </>
  )
}
