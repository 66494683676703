import React, { useState } from 'react'
import {
  List,
  ListItem,
  makeStyles,
} from '@material-ui/core'
import WorldMap from 'react-world-map'
import logo from '../../assets/logo.png'
import Plaid from './Plaid'

const platforms = [
  {
    _id: "as",
    name: "Middle East",
    subtext: "Open-Finance",
    image: logo,
  },
  {
    _id: "eu",
    name: "Europe",
    subtext: "Using Tink",
    image: "https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_256,w_256,f_auto,q_auto:eco,dpr_1/z8p5covxgzt7zixkdw0r",
  },
  {
    _id: "na",
    name: "USA & Canada",
    subtext: "Using Plaid",
    image: "https://pbs.twimg.com/profile_images/1410669135026036741/OqgsWyoU_400x400.jpg",
  },
]

const useStyles = makeStyles(({ spacing, palette }) => ({
  namesContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  list: {
    width: '100%',
  },
  container: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  listItem: {
    display: 'flex',
    direction: ({ isRtl }) => (isRtl ? 'rtl' : 'ltr'),
    flexDirection: 'column',
    opacity: 0,
    animation: '$fade-up 0.25s ease-in',
    animationFillMode: 'forwards',
  },
  listIndex: {
    marginRight: ({ isRtl }) => (isRtl ? 0 : spacing(2)),
    marginLeft: ({ isRtl }) => (isRtl ? spacing(2) : 0),
    display: 'inline-grid',
    width: 50,
    height: 50,
    alignItems: 'center',
    justifyItems: 'center',
    borderRadius: '50%',
    fontSize: 16,
  },
  bankText: {
    fontWeight: 500,
    display: 'inline-flex',
    fontSize: 19,
    margin: spacing(),
    marginLeft: 0,
    marginRight: 0,
  },
  line: {
    width: 50,
    height: 50,
    borderRadius: '50%',
  },
  subtext: {
    marginBottom: spacing(),
    display: 'inline-flex',
    color: 'rgb(200, 200, 200)',
  },
  '@keyframes fade-up': {
    from: {
      left: -20
    },
    to: {
      opacity: 1,
      left: 0,
    },
  },
}), { name: 'ChoosePlatform' })

export default function ChoosePlatform(props) {
  const [selected, onSelect] = useState(null)
  const [isPlaid, setIsPlaid] = useState(false)
  const classes = useStyles(props)
  const {
    setStep,
    step,
  } = props

  const onClick = (name) => {
    if (name === 'na') setIsPlaid(true)
    if (name === 'as') setStep(step + 1)
    if (name === 'eu') setStep(step + 1.5)
  }

  return (
    <>
      {!isPlaid && (
        <>
          <WorldMap selected={selected} onSelect={() => { }} />
          <List aria-label="banks list" className={classes.list}>
            {platforms.map(({
              image, name, _id, subtext,
            }, idx) => (
              <ListItem
                key={_id}
                button
                onClick={() => onClick(_id)}
                className={classes.listItem}
                style={{ animationDelay: `${idx * 100}ms` }}
                onMouseEnter={() => onSelect(_id)}
                onMouseLeave={() => onSelect(null)}
              >
                <div className={classes.container}>
                  <div
                    className={classes.listIndex}
                  >
                    <div
                      className={classes.line}
                      role="button"
                      tabIndex={0}
                      style={{
                        backgroundImage: `url(${image})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                      }}
                      alt="grid-button"
                    />
                  </div>
                  <div className={classes.namesContainer}>
                    <div
                      className={classes.bankText}
                    >
                      {name}
                    </div>
                    <div className={classes.subtext}>
                      {subtext}
                    </div>
                  </div>
                </div>
              </ListItem>
            ))}
          </List>
        </>
      )}
      {isPlaid && (<Plaid setIsPlaid={setIsPlaid} />)}
    </>
  )
}