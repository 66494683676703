const options = {
  HE: {
    shareDataUntil: 'עד לאיזה תאריך תהיה מעוניין לשתף את המידע',
    validUntil: 'תקף עד',
  },
  EN: {
    shareDataUntil: 'Until when would you like to share your data',
    validUntil: 'Valid until',
  },
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (id, type) => options[id][type]
