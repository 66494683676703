import React, { useState } from 'react'
import { searchBankTexts } from '../../texts'
import SearchBar from '../Common/SearchBar'
import BanksList from './BanksList'

export default function ChooseBank(props) {
  const {
    banks = [],
    language = { isRtl: false, id: 'EN' },
    setSelectedBank,
    setStep,
    step,
  } = props

  const [value, setValue] = useState('')

  const filteredBanks = Object.values(banks).filter(({ bankId }) => bankId.toLowerCase().includes(value))
  const onClick = (id) => {
    setSelectedBank(id)
    setStep(step + 1)
  }

  return (
    <>
      <SearchBar
        value={value}
        onChange={({ target: { value: val } }) => {
          setValue(val)
        }}
        isRtl={language.isRtl}
        label={searchBankTexts(language.id, 'searchBank')}
      />
      <BanksList banks={filteredBanks} onClick={onClick} />
    </>
  )
}