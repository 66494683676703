import React, { useState } from "react"
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

const COLORS = ["#ecba82", "#18ca92", "#1274e3", "#6e32e5", "#f3455c"]
let counter = 0
const getColorByCounter = () => {
  counter += 1
  return COLORS[counter % COLORS.length]
}

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'left',
    marginTop: theme.spacing(2),
  },
  wrapper: {
    cursor: 'pointer',
    border: '1px solid',
    borderColor: ({ invert }) => invert ? 'white' : '#111111',
    backgroundColor: ({ invert }) => invert ? '#111111' : 'white',
    borderRadius: '2px',
    display: 'inline-flex',
    flexDirection: 'row',
    height: '36px',
    outline: 'none',
    padding: '0',
    fontSize: '14px',
    color: ({ invert }) => invert ? 'white' : '#333333',
    fontFamily: '"Open Sans", sans-serif',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    verticalAlign: 'middle',
    textDecoration: 'none',
    justifyContent: ' center',
    transitionDuration: '100ms',
    transitionProperty: 'background-color, border-color',
    transitionTimingFunction: 'ease-out',
    '&:hover': {
      borderColor: ({ color, invert }) => invert ? 'white' : color,
      color: ({ color, invert }) => invert ? 'white' : color,
      backgroundColor: ({ color, invert }) => invert ? color : 'white',
    },
  },
}))

export default function Button(props) {
  const [color, setColor] = useState(getColorByCounter())
  const classes = useStyles({ color, ...props })
  const style = props.fill ? {
    width: '100%',
  } : {}
  return (
    <div className={classNames(classes.root, props.overwriteRoot)} style={style} >
      <div className={classNames(classes.wrapper, props.overwriteClassName)} onClick={props.unClickable ? () => { } : props.onClick} onMouseLeave={() => setColor(getColorByCounter())}>
        <div className="code">
          {props.children}
        </div>
      </div>
    </div>
  )
}