import React from "react"
import Note from "../Note"
import Button from "../Button"

import "./index.css"

const Error = (props) => {
  return (
    <>
      <div className="errorTop"></div>
      <div className="errorContainer">
        <Note method="error" className="code">
          {props.error.status_code ? props.error.status_code : "error"}
        </Note>
        <div className="errorContents">
          <div className="errorItem">
            <span className="errorTitle">Error code: </span>
            <span className="errorData">
              <div className="errorCode">
                {props.error.error_code}
                <div className="pinkBox"></div>
              </div>
            </span>
          </div>
          <div className="errorItem">
            <span className="errorTitle">Type: </span>
            <span className="errorData">{props.error.error_type}</span>
          </div>
          <div className="errorItem">
            <span className="errorTitle">Message: </span>
            <span className="errorMessage">
              {props.error.display_message == null
                ? props.error.error_message
                : props.error.display_message}
            </span>
          </div>
        </div>
        <Button
          small
          className="learnMore"
        >
          Learn more
        </Button>
      </div>
    </>
  )
}

Error.displayName = "Error"

export default Error
