import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import InputBase from '@material-ui/core/InputBase'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    boxShadow: 'none',
    direction: ({ isRtl }) => (isRtl ? 'rtl' : 'ltr'),
    background: 'rgb(230, 230, 230)',
    width: '98%',
  },
  input: {
    marginLeft: ({ isRtl }) => (isRtl ? 0 : spacing()),
    marginRight: ({ isRtl }) => (isRtl ? spacing() : 0),
    flex: 1,
    fontSize: 17,
  },
  iconButton: {
    fontWeight: 'bold',
    '& svg': {
      width: 25,
      height: 25,
    }
  },
}), { name: 'SearchBar' })

export default function SearchBar(props) {
  const classes = useStyles(props)
  const {
    value,
    label,
    onChange = () => { },
    onFocus = () => { },
    onBlur = () => { },
  } = props

  return (
    <Paper component="form" className={classes.root}>
      <InputBase
        className={classes.input}
        value={value}
        onChange={onChange}
        placeholder={label}
        inputProps={{ 'aria-label': 'search' }}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      <IconButton fontSize="large" type="submit" disabled className={classes.iconButton} aria-label="search">
        <SearchIcon />
      </IconButton>
    </Paper>
  )
}
