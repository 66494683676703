import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Container,
  Dialog,
  IconButton,
  Toolbar,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import ArrowBack from '@material-ui/icons/ArrowBack'
import Slide from '@material-ui/core/Slide'
import CircleLoading from 'react-loadingg/lib/CircleLoading'
import ChooseBank from './ChooseBank'
import ChoosePlatform from './ChoosePlatform'
import ChooseMarket from './ChooseMarket'
import ValidUntil from './ValidUntil'
import Welcome from './Welcome'
import InfoBuckets from './InfoBuckets'

const banks = [
  {
    _id: "mizrahi",
    bankId: "Mizrahi",
    site: "www.mizrahi-tefahot.co.il",
    image: "https://www.maala.org.il/wp-content/uploads/2015/07/%D7%91%D7%A0%D7%A7-%D7%9E%D7%96%D7%A8%D7%97%D7%99-%D7%98%D7%A4%D7%97%D7%95%D7%AA.png",
  },
  {
    _id: "leumi",
    bankId: "Lemui",
    site: "www.leumi.co.il",
    image: "https://softwares.center/wp-content/uploads/2017/02/9445646.png",
  },
  {
    _id: "discount",
    bankId: "Discount",
    site: "www.discountbank.co.il",
    image: "https://yt3.ggpht.com/ytc/AKedOLTFpzXVupjFjsCp_A-YU565qvRtDHJbtaUdcb_X=s900-c-k-c0x00ffffff-no-rj",
  },
  {
    _id: "fibi",
    bankId: "Binleumi",
    site: "www.fibi.co.il",
    image: "https://www.theta1.co.il/wp-content/uploads/2021/02/%D7%91%D7%99%D7%A0%D7%9C%D7%90%D7%95%D7%9E%D7%99-%D7%9C%D7%95%D7%92%D7%95.jpg",
  },
  {
    _id: "bankhapoalim",
    bankId: "Bank Hapoalim",
    site: "www.bankhapoalim.co.il",
    image: "https://yt3.ggpht.com/ytc/AKedOLRXzOxFGt3F_UHfgcGgD2fmk9t3DfCGewdbAKqo=s900-c-k-c0x00ffffff-no-rj",
  },
  {
    _id: "mercantile",
    bankId: "Mercantile",
    site: "www.mercantile.co.il",
    image: "https://ramot-mall.co.il/wp-content/uploads/2019/12/LOGO-43.png",
  },
  {
    _id: "peper",
    bankId: "Perer",
    site: "www.pepper.co.il",
    image: "https://www.pepper.co.il/wp-content/themes/pepper/images/logo.svg",
  },
  {
    _id: "jerusalem",
    bankId: "Jerusalem",
    site: "www.bankjerusalem.co.il",
    image: "https://mbr.co.il/wp-content/uploads/2020/09/13212313.jpg",
  },
  {
    _id: "yahav",
    bankId: "Yahav",
    site: "www.bank-yahav.co.il",
    image: "https://www.bank-yahav.co.il/SiteData/1/Templates/Yahav/img/logo.png",
  },
  {
    _id: "igud",
    bankId: "Igud",
    site: "www.unionbank.co.il",
    image: "https://www.unionbank.co.il/Igud/Img/Logo_Main.png",
  },
  {
    _id: "digibank",
    bankId: "Digital Bank",
    site: "www.digibank.co.il/",
    image: "https://www.digibank.co.il/Content/images/footer-logo.svg",
  },
]

const markets = [
  {
    _id: "GB",
    site: "GB",
    bankId: "United Kingdom",
    image: "https://image.flaticon.com/icons/png/512/197/197374.png",
  },
  {
    _id: "SE",
    site: "SE",
    bankId: "Sweden",
    image: "https://image.flaticon.com/icons/png/512/323/323364.png",
  },
  {
    _id: "ES",
    site: "ES",
    bankId: "Spain",
    image: "https://image.flaticon.com/icons/png/512/197/197593.png",
  },
  {
    _id: "PT",
    site: "PT",
    bankId: "Portugal",
    image: "https://image.flaticon.com/icons/png/512/197/197463.png",
  },
  {
    _id: "PL",
    site: "PL",
    bankId: "Poland",
    image: "https://image.flaticon.com/icons/png/512/323/323338.png",
  },
  {
    _id: "NL",
    site: "NL",
    bankId: "Netherlands",
    image: "https://image.flaticon.com/icons/png/512/323/323275.png",
  },
  {
    _id: "IT",
    site: "IT",
    bankId: "Italy",
    image: "https://image.flaticon.com/icons/png/512/323/323325.png",
  },
  {
    _id: "IE",
    site: "IE",
    bankId: "Ireland",
    image: "https://image.flaticon.com/icons/png/512/197/197567.png",
  },
  {
    _id: "DE",
    site: "DE",
    bankId: "Germany",
    image: "https://image.flaticon.com/icons/png/512/197/197571.png",
  },
  {
    _id: "FR",
    site: "FR",
    bankId: "France",
    image: "https://image.flaticon.com/icons/png/512/197/197560.png",
  },
  {
    _id: "FI",
    site: "FI",
    bankId: "Finland",
    image: "https://image.flaticon.com/icons/png/512/197/197585.png",
  },
  {
    _id: "DK",
    site: "DK",
    bankId: "Denmark",
    image: "https://image.flaticon.com/icons/png/512/323/323320.png",
  },
  {
    _id: "BE",
    site: "BE",
    bankId: "Belgium",
    image: "https://image.flaticon.com/icons/png/512/323/323291.png",
  },
  {
    _id: "AT",
    site: "AT",
    bankId: "Austria",
    image: "https://image.flaticon.com/icons/png/512/197/197447.png",
  },
]

const useStyles = makeStyles(({ spacing }) => ({
  dialog: {
    width: 400,
    height: 675,
  },
  appBar: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: spacing(2),
    zIndex: 3,
    border: 0,
  },
  logoContainer: {
    display: 'flex',
    fontWeight: 'bold',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    userSelect: 'none',
    '& > div': {
      letterSpacing: '1.5px',
      fontSize: 24,
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  icon: {
    '& svg': {
      width: 25,
      height: 25,
    },
  },
}), { name: 'CreateConsent' })

const Transition = React.forwardRef((props, ref) => (<Slide direction="up" ref={ref} {...props} />))

export default function CreateConsent(props) {
  const classes = useStyles(props)
  const [selectedBank, setSelectedBank] = useState('')
  const {
    open,
    handleClose,
    setStep,
    step,
    isMobile,
  } = props

  const backClicked = () => {
    if (step === 1) return handleClose()
    setStep(Math.floor(step - 1))
  }

  return (
    <Dialog
      fullScreen={isMobile}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <div className={isMobile ? '' : classes.dialog}>
        <div>
          <Toolbar className={classes.appBar}>
            <IconButton className={classes.icon} edge="start" color="inherit" onClick={backClicked} aria-label="close">
              <ArrowBack />
            </IconButton>
            <div className={classes.logoContainer}>
              <div>Open-Finance</div>
            </div>
            <IconButton className={classes.icon} edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </div>
        <Container className={classes.container}>
          {step === 0 && (<Welcome step={step} setStep={setStep} />)}
          {step === 1 && (<ChoosePlatform step={step} setStep={setStep} />)}
          {step === 2.5 && (<ChooseMarket banks={markets} />)}
          {step === 2 && (<ChooseBank step={step} banks={banks} setStep={setStep} setSelectedBank={setSelectedBank} />)}
          {step === 3 && (<InfoBuckets step={step} banks={banks} selectedBank={selectedBank} setStep={setStep} />)}
          {step === 4 && (<ValidUntil step={step} banks={banks} selectedBank={selectedBank} setStep={setStep} />)}
          {step === 5 && (<CircleLoading />)}
        </Container>
      </div>
    </Dialog>
  )
}