import React from "react"

import Identity from "./Identity"

import "./index.css"

const Table = (props) => {
  const maxRows = 15
  // regular table
  const headers = props.categories.map((category, index) => (
    <th key={index} className="headerField">
      {category.title}
    </th>
  ))

  const rows = props.data
    .map((item, index) => (
      <tr key={index} className="dataRows">
        {props.categories.map((category, index) => (
          <td key={index} className="dataField">
            {item[category.field]}
          </td>
        ))}
      </tr>
    ))
    .slice(0, maxRows)

  return props.isIdentity ? (
    <Identity data={props.data} categories={props.categories} />
  ) : (
    <table className="dataTable">
      <thead className="header">
        <tr className="headerRow">{headers}</tr>
      </thead>
      <tbody className="body">{rows}</tbody>
    </table>
  )
}

Table.displayName = "Table"

export default Table
