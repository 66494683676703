import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import Button from '../Components/Button'
import logo from '../assets/logo512.png'
import blank from '../assets/blank.png'
import { useMousePosition } from "../Hooks/useMousePosition"
import ApproveSvg from '../Components/AprroveSvg'
import background from '../assets/background.svg'

const urlSearchParams = new URLSearchParams(window.location.search)
const { token } = Object.fromEntries(urlSearchParams.entries())
const divideBy = 40

const getValue = (first, second, dim) => Math.floor((first[dim] - second[dim]) / divideBy)

const useStyles = makeStyles((theme) => ({
  backgroundMain: {
    background: `url(${background})`,
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: -1,
    top: 0,
    left: 0,
  },
  hero: {
    marginTop: '4%',
  },
  container: {
    marginBottom: '9vh',
  },
  heroWrapper: {
    display: 'flex',
    margin: '0 auto',
    maxWidth: '142.2rem',
  },
  heroContent: {
    width: '50%',
    marginTop: '9vh',
    animation: '$fade-in 0.6s ease-in',
  },
  paragraph: {
    maxWidth: 485,
    margin: 10,
    fontSize: 20,
  },
  buttonRoot: {
    marginTop: '5vh',
  },
  button: {
    fontSize: 20,
    height: 54,
    width: 184,
  },
  heroImage: {
    width: '50%',
    position: 'relative',
  },
  image: {
    width: '100%',
    top: -20,
    left: 100,
  },
  moving: {
    position: 'absolute',
    transitionDuration: '3s',
    transitionTimingFunction: 'ease',
  },
  background: {
    borderRadius: '100%',
    backgroundImage: 'linear-gradient(298deg, #41ca94, #9fc5e84a)',
    top: -30,
    left: 100,
  },
  feature: {
    width: '20%',
    opacity: 0,
    animation: '$fade-in-up 0.6s ease-in',
    animationFillMode: 'forwards',
  },
  mainText: {
    fontWeight: 700,
    margin: 0,
    fontSize: 24,
    marginLeft: 16,
  },
  secondaryText: {
    fontSize: 18,
    marginLeft: 16,
    marginTop: 12,
    color: '#16ca94',
  },
  '@keyframes fade-in-up': {
    from: {
      transform: 'translate3d(0,40px,0)'
    },
    to: {
      opacity: 1,
      transform: 'translate3d(0,0,0)',
    },
  },
  '@keyframes fade-in': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
}))
export default function Home() {
  const classes = useStyles()
  const history = useHistory()
  const position = useMousePosition()
  const [dimensions, setDimensions] = useState({ x: 0, y: 0, width: 750 })

  const callBackRef = useCallback((domNode) => {
    if (domNode) {
      const a = domNode.getBoundingClientRect()
      const {
        width: widthBounding,
      } = a
      const elementPos = { x: window.innerWidth / 2, y: window.innerHeight / 2, width: widthBounding }
      setDimensions(elementPos)
    }
  }, [])

  return (
    <div className={classes.hero}>
      <div className={classes.backgroundMain} />
      <div className={classes.container}>
        <div className={classes.heroWrapper}>
          <div className={classes.heroContent}>
            <h1>Banking made simple</h1>
            <p className={classes.paragraph} >
              The easiest way for people to connect their financial accounts to an app. Start building with Open-Finance's APIs.
            </p>
            <Button
              invert
              overwriteRoot={classes.buttonRoot}
              overwriteClassName={classes.button}
              onClick={() => history.push(`/connect?token=${token}`)}
            >
              Get Started
            </Button>
          </div>
          <div ref={callBackRef} className={classes.heroImage}>
            <img
              className={classNames(classes.moving, classes.background)}
              src={blank}
              alt="background"
              style={{
                height: dimensions.width,
                width: dimensions.width,
                transform: `translate(${getValue(dimensions, position, 'x')}px, ${getValue(dimensions, position, 'y')}px)`
              }}
            />
            <img
              className={classNames(classes.moving, classes.image)}
              src={logo}
              alt="logo"
              style={{
                transform: `translate3d(${getValue(position, dimensions, 'x')}px, ${getValue(position, dimensions, 'y')}px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(15deg) skew(0deg, 0deg)`
              }}
            />
          </div>
        </div>
      </div>
      <div className={classes.heroWrapper}>
        <div className={classes.feature}>
          <ApproveSvg />
          <p className={classes.mainText} >Quick setup</p>
          <p className={classes.secondaryText} >Connect to every bank in 15 minutes.</p>
        </div>
        <div className={classes.feature} style={{ animationDelay: '300ms' }} >
          <ApproveSvg />
          <p className={classes.mainText} >Easy to use</p>
          <p className={classes.secondaryText} >Simple yet powerful api for developers.</p>
        </div>
        <div className={classes.feature} style={{ animationDelay: '600ms' }} >
          <ApproveSvg />
          <p className={classes.mainText} >For everyone</p>
          <p className={classes.secondaryText} >For businesses and ideas of all sizes.</p>
        </div>
      </div>
    </div>
  )
}