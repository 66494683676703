import React, { useState } from "react"

import Table from "../Table"
import Error from "../Error"

import "./index.css"
import Note from "../Note"
import Button from "../Button"
import { REACT_APP_DUMMY_DATA_SERVER_URL } from '../../consts'

const Endpoint = (props) => {
  const [showTable, setShowTable] = useState(false)
  const [transformedData, setTransformedData] = useState([])
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const getData = async () => {
    setIsLoading(true)
    try {
      const response = await fetch(`${REACT_APP_DUMMY_DATA_SERVER_URL}/${props.endpoint}`, { method: 'GET' })
      const data = await response.json()
      if (data.error) {
        setError(data.error)
        setIsLoading(false)
        return
      }
      setTransformedData(props.transformData(data)) // transform data into proper format for each individual product
      setShowTable(true)
      setIsLoading(false)
    } catch (e) {
      console.log(e)
      setError({
        error_code: "UNKNOWN_ERROR",
        error_type: "UNKNOWN_ERROR",
        status_code: 500,
        error_message: "An unknown error occurred.",
      })
      setIsLoading(false)
      return
    }
  }

  return (
    <>
      <div className="endpointContainer">
        <Note method={props.method} className="post">
          {props.method}
        </Note>
        <div className="endpointContents">
          <div className="endpointHeader">
            {props.name != null && (
              <span className="endpointName">{props.name}</span>
            )}
            <span className="schema">{props.schema}</span>
          </div>
          <div className="endpointDescription">{props.description}</div>
        </div>
        <div className="buttonsContainer">
          <Button
            small
            centered
            wide
            secondary
            className="sendRequest"
            unClickable={props.comingSoon}
            onClick={getData}
          >
            {props.comingSoon ? 'Coming soon' : (isLoading ? "Loading..." : 'Send request')}
          </Button>
        </div>
      </div>
      {showTable && (
        <Table
          categories={props.categories}
          data={transformedData}
          isIdentity={props.endpoint === "identity"}
        />
      )}
      {error != null && <Error error={error} />}
    </>
  )
}

Endpoint.displayName = "Endpoint"

export default Endpoint
