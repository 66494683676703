import React from 'react'

export default function Welcome(props) {
  const {
    setStep,
    step,
  } = props
  const onClick = () => setStep(step + 1)

  return (
    <>
      <div onClick={onClick}>
        Continue
      </div>
    </>
  )
}