const options = {
  HE: {
    searchBank: 'חפש בנק',
  },
  EN: {
    searchBank: 'Search Bank',
  },
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (id, type) => options[id][type]
