/* eslint-disable max-len */
import React from 'react'

function HouseIcon() {
  return (
    <svg width="33" height="29" viewBox="0 0 33 29" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M16.6328 28.3833L1.00024 10.5238L8.21525 1H25.0503L32.2653 10.5238L16.6328 28.3833Z" fill="white" stroke="rgb(0, 0, 0)" stroke-linecap="round" stroke-linejoin="round"></path><path d="M8.21521 1L10.5457 10.5238L16.6327 28.3833" stroke="rgb(0, 0, 0)" stroke-linecap="round" stroke-linejoin="round"></path><path d="M25.0502 1L22.6452 10.5238L16.7313 28.3833" stroke="rgb(0, 0, 0)" stroke-linecap="round" stroke-linejoin="round"></path><path d="M32.2653 10.5239H1.00024" stroke="rgb(0, 0, 0)" stroke-linecap="round" stroke-linejoin="round"></path></svg>
  )
}

export default HouseIcon
