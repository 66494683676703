import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom'
import Home from './Pages/Home'
import Connect from './Pages/Connect'
import Products from './Pages/Products'
import logo from './assets/logo.png'
import background from './assets/background.svg'

const COLORS = ["#16ca94", "#18ca92", "#1274e3", "#6e32e5", "#f3455c"]
let counter = 0
const getColorByCounter = () => {
  counter += 1
  return COLORS[counter % COLORS.length]
}

const urlSearchParams = new URLSearchParams(window.location.search)
const { token } = Object.fromEntries(urlSearchParams.entries())

const useStyles = makeStyles(() => ({
  background: {
    background: `url(${background})`,
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: -1,
    top: 0,
    left: 0,
  },
  navbar: {
    color: '#111',
    boxSizing: 'inherit',
    border: 0,
    font: 'inherit',
    lineHeight: '1.5',
    verticalAlign: 'baseline',
    margin: 0,
    padding: 0,
    alignItems: 'center',
    height: '6.6rem',
    justifyContent: 'space-between',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '1880px',
    top: 0,
    zIndex: 12,
    display: 'flex',
  },
  logoWrapper: {
    color: '#111',
    boxSizing: 'inherit',
    border: 0,
    font: 'inherit',
    lineHeight: 1.5,
    verticalAlign: 'baseline',
    margin: 0,
    padding: 0,
    alignItems: 'center',
    display: 'flex',
    paddingRight: 16,
  },
  logo: {
    color: '#111',
    cursor: 'pointer',
    width: '162px',
    aspectRatio: 'auto 126 / 48',
    boxSizing: 'inherit',
    font: 'inherit',
    lineHeight: '1.5',
    margin: '0',
    padding: '0',
    display: 'inline-block',
    height: 'auto',
    maxWidth: '100%',
    verticalAlign: 'middle',
    border: '0',
  },
  menu: {
    color: '#111',
    boxSizing: 'inherit',
    border: '0',
    font: 'inherit',
    verticalAlign: 'baseline',
    margin: '0',
    padding: '0',
    lineHeight: '1.5',
    alignItems: 'stretch',
    display: 'flex',
    height: '100%',
    justifyContent: 'space-between',
    listStyle: 'none',
    marginBottom: '0',
    marginLeft: '0',
  },
  menuItem: {
    color: '#111',
    listStyle: 'none',
    boxSizing: 'inherit',
    border: '0',
    font: 'inherit',
    lineHeight: '1.5',
    verticalAlign: 'baseline',
    margin: '0',
    padding: '0',
    fontSize: 18,
    display: 'block',
    flex: '0 1 auto',
    fontWeight: 600,
    paddingLeft: '3.04rem',
    paddingRight: '3.04rem',
    textAlign: 'center',
    pointer: 'cursor',
    width: 100,
    '&:hover': {
      '& div': {
        width: '100%',
      }
    },
  },
  menuButton: {
    color: '#111',
    listStyle: 'none',
    font: 'inherit',
    fontSize: 'inherit',
    fontWeight: 600,
    boxSizing: 'inherit',
    lineHeight: '6.6rem',
    textDecoration: 'none',
    transitionDuration: '200ms',
    transitionTimingFunction: 'ease-out',
  },
  menuBar: {
    backgroundColor: '#111',
    bottom: '0',
    content: '',
    display: 'block',
    height: '4px',
    marginTop: '-4px',
    position: 'relative',
    transitionDuration: '200ms',
    transitionProperty: 'width',
    transitionTimingFunction: 'ease',
    width: '0',
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  buttons: {
    boxSizing: 'inherit',
    font: 'inherit',
    verticalAlign: 'baseline',
    color: '#111',
    cursor: 'pointer',
    fontFamily: 'Cern, Helvetica, Arial, sans-serif',
    textDecoration: 'none',
    transitionDuration: '0.1s',
    transitionProperty: 'background-color, border-color',
    transitionTimingFunction: 'ease-out',
    outlineOffset: '-0.1rem',
    background: 'rgba(0, 0, 0, 0)',
    border: '1px solid #111111',
    outlineColor: '#0868b8',
    borderRadius: '2px',
    fontSize: 18,
    lineHeight: '1',
    height: 20,
    margin: '0 0 1.6rem',
    padding: '1.6rem',
    marginRight: '0',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: '.8rem',
    position: 'relative',
    textAlign: 'left',
    marginBottom: '0',
    minWidth: '178px',
    '&:hover': {
      borderColor: ({ color }) => color,
      '& a': {
        color: ({ color }) => color,
      }
    },
  },
}))

function NotFound() {
  return (
    <div>
      <h1>Not Found</h1>
    </div>
  )
}
export default function MainRouter() {
  const [color, setColor] = useState(getColorByCounter())
  const classes = useStyles({ color })

  return (
    <Router basename="/">
      <div>
        <div className={classes.navbar}>
          {/* logo section */}
          <div className={classes.logoWrapper}>
            <img className={classes.logo} src={logo} alt="logo" />
          </div>
          {/* menu section */}
          <ul className={classes.menu}>
            <li className={classes.menuItem}>
              <Link className={classes.menuButton} to={`/?token=${token}`}>Home</Link>
              <div className={classes.menuBar} />
            </li>
            <li className={classes.menuItem}>
              <Link className={classes.menuButton} to={`/connect?token=${token}`}>Connect</Link>
              <div className={classes.menuBar} />
            </li>
            <li className={classes.menuItem}>
              <Link className={classes.menuButton} to={`/products?token=${token}`}>Products</Link>
              <div className={classes.menuBar} />
            </li>
          </ul>
          {/* login menu section */}
          <div className={classes.menu}>
            <div className={classes.menuItem}>
              <Link className={classes.menuButton} to="/">Login</Link>
              <div className={classes.menuBar} />
            </div>
            <div className={classes.buttonWrapper} onMouseLeave={() => setColor(getColorByCounter())} >
              <div className={classes.buttons}>
                <Link className={classes.menuButton} to="/">Register</Link>
              </div>
            </div>
          </div>
        </div>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/connect" component={Connect} />
          <Route path="/products" component={Products} />
          <Route component={NotFound} />
        </Switch>
      </div>
    </Router>
  )
}