import React, { useEffect, useState } from 'react'
import CircleLoading from 'react-loadingg/lib/CircleLoading'
import { makeStyles } from '@material-ui/core'
import { PlaidLink } from 'react-plaid-link'
import { v4 } from 'uuid'
import axios from 'axios'

const request = async (params) => {
  try {
    const {
      data,
    } = await axios(params)

    return data
  }
  catch (e) {
    console.log(e)
  }
}

const plaidUserId = v4()

const plaidBody = {
  userId: plaidUserId,
  clientName: 'Everybyte',
  products: ['transactions'],
  countryCodes: ['US'],
  language: 'en',
  webhook: 'http://localhost:3004',
}

const useStyles = makeStyles(({ spacing }) => ({
  plaidButton: {
    opacity: '0 !important',
  }
}), { name: 'Plaid' })

export default function Plaid({ setIsPlaid }) {
  const classes = useStyles()
  const [linkToken, setLinkToken] = useState('')
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchLinkToken = async () => {
      const tokenResponse = await request({
        url: 'https://api-sandbox.open-finance.ai/demo-identity/create-plaid-link',
        method: 'post',
        data: plaidBody,
      })

      if (tokenResponse) setLinkToken(tokenResponse.linkToken)
      setLoading(false)
      setTimeout(() => document.querySelectorAll("[id^=plaid-button]")[0].click(), 1000)
    }

    fetchLinkToken()
  }, [])

  return (
    <>
      <CircleLoading />
      {!loading && (<PlaidLink
        token={linkToken}
        onSuccess={() => { }}
        onExit={() => setIsPlaid(false)}
        env="development"
        className={classes.plaidButton}
      >
        <span id="plaid-button" style={{ lineHeight: 1.75 }}>
          Open Plaid link
        </span>
      </PlaidLink>)}
    </>
  )
}