import React, { useState } from 'react'
import { makeStyles, TextField, Typography } from '@material-ui/core'
import publicIp from 'public-ip'
import Button from '../Button'
import BanksList from './BanksList'
import { bigButtonTexts, validUntilTexts } from '../../texts'
import axios from 'axios'
import { useHistory } from 'react-router-dom'

const formatDate = () => {
  const today = new Date()
  const d = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000)
  let month = `${d.getMonth() + 1}`
  let day = `${d.getDate()}`
  const year = d.getFullYear()

  if (month.length < 2) month = `0${month}`
  if (day.length < 2) day = `0${day}`

  return [year, month, day].join('-')
}


const useStyles = makeStyles(({ spacing }) => ({
  mainText: {
    fontSize: 19,
    fontWeight: 400,
    marginBottom: 35,
    direction: ({ isRtl }) => (isRtl ? 'rtl' : 'ltr'),
  },
  dateContainer: {
    margin: '1em 0',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: spacing(15),
  },
  button: {
    width: '100%',
    height: 50,
    fontSize: 17,
  },
  input: {
    '& label': {
      fontSize: 17
    },
    '& input': {
      fontSize: 17,
    },
  },
}), { name: 'ValidUntil' })

export default function ValidUntil(props) {
  const classes = useStyles(props)
  const {
    banks = [],
    selectedBank,
    setStep,
    step,
    language = { isRtl: false, id: 'EN' },
  } = props
  const [date, setDate] = useState(formatDate())
  const history = useHistory()
  const userInfoToShare = []

  const filteredBanks = banks.filter(({ bankId }) => bankId === selectedBank)

  const startCreateConsent = async () => {
    setStep(step + 1)

    const ip = await publicIp.v4()
    const urlSearchParams = new URLSearchParams(window.location.search)
    const { token } = Object.fromEntries(urlSearchParams.entries())

    const data = {
      aspspId: '20',
      validUntil: date,
      psuId: '245938880',
      webhookUrl: userInfoToShare.webhookUrl || 'www.open-finance.io',
      accounts: [],
      transactions: [],
      balances: [],
    }
    try {
      const {
        data: {
          scaOAuth
        }
      } = await axios({
        // url: 'http://localhost:3020/',
        url: 'https://api-sandbox.open-finance.ai/psd2-consents/',
        headers: {
          Authorization: `Bearer ${token}`,
          userId: '318358329',
          clientId: '1234',
          psuIpAddress: ip || '127.0.0.1',
        },
        method: 'POST',
        data: {
          ...data,
          combinedServiceIndicator: false,
          accounts: [],
          transactions: [],
          balances: [],
          frequencyPerDay: 100,
          recurringIndicator: true,
        },
      })
      window.location.replace(scaOAuth)
    } catch (error) {
      console.error(error)
      history.push('/products')
    }
  }

  return (
    <>
      <BanksList banks={filteredBanks} isButton={false} />
      <div
        className={classes.dateContainer}
      >
        <Typography
          className={classes.mainText}
        >
          {validUntilTexts(language.id, 'shareDataUntil')}
        </Typography>
        <TextField
          id="date"
          label={validUntilTexts(language.id, 'validUntil')}
          type="date"
          value={date}
          className={classes.input}
          onChange={({ target: { value } }) => setDate(value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
      <div className={classes.buttonContainer}>
        <Button
          onClick={startCreateConsent}
          overwriteClassName={classes.button}
          invert
          fill
        >
          {bigButtonTexts(language.id, 'connect')}
        </Button>
      </div>
    </>
  )
}
