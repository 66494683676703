import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '../Components/Button'
import logo from '../assets/feature.png'
import CreateConsentDialog from '../Components/CreateConsent/CreateConsentDialog'
import background from '../assets/background.svg'

const useStyles = makeStyles((theme) => ({
  background: {
    background: `url(${background})`,
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: -1,
    top: 0,
    left: 0,
  },
  hero: {
    marginTop: 100,
  },
  container: {
  },
  heroWrapper: {
    display: 'flex',
    margin: '0 auto',
    maxWidth: '142.2rem',
  },
  heroContent: {
    width: '50%',
    marginTop: 100,
    animation: '$fade-in 0.6s ease-in',
  },
  paragraph: {
    maxWidth: 485,
    margin: 10,
    fontSize: 20,
  },
  buttonRoot: {
    marginTop: 60,
  },
  button: {
    fontSize: 20,
    height: 54,
    width: 184,
  },
  heroImage: {
    width: '50%',
  },
  image: {
    position: 'absolute',
    width: '50%',
  },
  '@keyframes fade-in': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
}))
export default function Home() {
  const [open, setOpen] = useState(false)
  const [step, setStep] = useState(1)
  const classes = useStyles()

  return (
    <div className={classes.hero}>
      <div className={classes.background} />
      <div className={classes.container}>
        <div className={classes.heroWrapper}>
          <div className={classes.heroContent}>
            <h1>Open-Finance Link.</h1>
            <p className={classes.paragraph} >
              Explore Open-Finance and Open-Finance Link. Easily integrate next-generation open banking consent link into any app
            </p>
            <Button
              invert
              overwriteRoot={classes.buttonRoot}
              overwriteClassName={classes.button}
              onClick={() => setOpen(true)}
            >
              Launch Demo
            </Button>
          </div>
          <div className={classes.heroImage}>
            <img className={classes.image} src={logo} alt="logo" />
          </div>
        </div>
      </div>
      <div className={classes.keyFeatures}>
        <div className={classes.feature}></div>
        <div className={classes.feature}></div>
        <div className={classes.feature}></div>
      </div>
      <CreateConsentDialog
        step={step}
        open={open}
        setStep={setStep}
        handleClose={() => setOpen(false)}
      />
    </div>
  )
}