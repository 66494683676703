import './App.css'
import MainRouter from './MainRouter.jsx'

function App() {
  return (
    <MainRouter />
  )
}

export default App
