import React from 'react'
import Header from '../Components/Headers'
import Products from "../Components/ProductTypes/Products"
import './index.css'

export default function ProductsPage() {
  return (
    <div className="App">
      <div className="container">
        <Header />
      </div>
      <div className="container2">
        <Products />
      </div>
    </div>
  )
}