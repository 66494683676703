import React from "react"

import "./index.css"

const TypeContainer = (props) => (
  <div className="container-products container">
    <h4 className="header">{props.productType}</h4>
    {props.children}
  </div>
)

TypeContainer.displayName = "TypeContainer"

export default TypeContainer
