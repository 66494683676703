const options = {
  HE: {
    createConsent: 'צור הסכמה',
    connect: 'התחבר',
  },
  EN: {
    createConsent: 'Create consent',
    connect: 'Connect',
  },
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (id, type) => options[id][type]
