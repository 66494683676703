import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import Button from '../Button'
import BanksList from './BanksList'
import CreditCard from '../../assets/CreditCard'
import HouseHold from '../../assets/HouseHold'
import Diamond from '../../assets/Diamond'
import Taxes from '../../assets/Taxes'

const options = [
  {
    name: 'ACCOUNTS',
    icon: () => <HouseHold />,
  },
  {
    name: 'TRANSACTIONS',
    icon: () => <Taxes />,
  },
  {
    name: 'BALANCES',
    icon: () => <Diamond />,
  },
  {
    name: 'CREDIT CARDS',
    selected: true,
    icon: () => <CreditCard />,
  },
]


const useStyles = makeStyles(({ spacing }) => ({
  mainText: {
    fontSize: 19,
    fontWeight: 400,
    marginBottom: 35,
    direction: ({ isRtl }) => (isRtl ? 'rtl' : 'ltr'),
  },
  dateContainer: {
    margin: '1em 0',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 45,
  },
  button: {
    width: '100%',
    height: 50,
    fontSize: 17,
  },
  input: {
    '& label': {
      fontSize: 17
    },
    '& input': {
      fontSize: 17,
    },
  },
  optionsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    width: '47%',
    height: '10rem',
    margin: '0.25rem',
    borderRadius: 8,
    transition: 'all 0.175s ease 0s',
    border: '0.0625rem solid rgb(230, 230, 230)',
    cursor: 'pointer',
    '&[aria-selected="true"]': {
      border: '0.0625rem solid rgb(0, 0, 0)',
      boxShadow: 'rgb(0 0 0 / 15%) 0px 0px 0.25rem',
      backgroundColor: 'rgb(240, 240, 240)',
    },
    '&:hover': {
      border: '0.0625rem solid rgb(0, 147, 147)',
      boxShadow: 'rgb(0 0 0 / 15%) 0px 0px 0.25rem',
      backgroundColor: 'rgb(250, 250, 250)',
    },
  },
  text: {
    fontWeight: 400,
    fontSize: 13,
    maxWidth: '140px',
    letterSpacing: '0.0625rem',
    transition: 'all 0.175s ease 0s',
    lineHeight: '1.4rem',
    marginTop: '0.625rem',
    color: 'rgb(97, 97, 97)',
    '&[aria-selected="true"]': {
      color: 'rgb(0, 0, 0)',
    },
    '&:hover': {
      color: 'rgb(0, 0, 0)',
    },
  },
}), { name: 'InfoBuckets' })

export default function InfoBuckets(props) {
  const classes = useStyles(props)
  const {
    banks = [],
    selectedBank,
    setStep,
    step,
  } = props
  const [selected, setSelected] = useState({
    'ACCOUNTS': true,
  })

  const filteredBanks = banks.filter(({ bankId }) => bankId === selectedBank)

  const onClick = async () => setStep(step + 1)

  return (
    <>
      <BanksList banks={filteredBanks} isButton={false} />
      <div
        className={classes.dateContainer}
      >
        <div className={classes.optionsContainer}>
          {options.map(({ name, icon }) => (
            <div
              onClick={() => {
                const selectedCp = { ...selected }
                selectedCp[name] = !selectedCp[name]
                setSelected(selectedCp)
              }}
              aria-selected={selected[name]}
              key={name}
              className={classes.card}
            >
              {icon()}
              <div aria-selected={selected[name]} className={classes.text}>
                {name}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={classes.buttonContainer}>
        <Button
          onClick={onClick}
          overwriteClassName={classes.button}
          invert
          fill
        >
          Continue
        </Button>
      </div>
    </>
  )
}
