const creditLimits = ['1000$', '15000$', '5000$', '10000$']

const formatCurrency = (number, code) => {
  if (number != null && number !== undefined) {
    return ` ${parseFloat(number).toFixed(2).toLocaleString("en")} ${code}`
  }
  return "no data"
}

export const transactionsLiveCategories = [
  {
    title: "Description",
    field: "description",
  },
  {
    title: "Amount",
    field: "amount",
  },
  {
    title: "Category",
    field: "category",
  },
  {
    title: "Date",
    field: "date",
  },
  {
    title: "Currency",
    field: "currency",
  },
  {
    title: "type",
    field: "type",
  },
]

export const transactionsRawCategories = [
  {
    title: "Description",
    field: "description",
  },
  {
    title: "Amount",
    field: "amount",
  },
  {
    title: "Date",
    field: "date",
  },
  {
    title: "Currency",
    field: "currency",
  },
  {
    title: "type",
    field: "type",
  },
]

export const transformTransactionsData = (data) => {

  const bookedTransactions = data.transactions.booked.map(transaction => {
    const {
      amount,
      category,
      bookingDate: { $date },
      currency,
      description,
    } = transaction

    return {
      description: description.replace(/(^.*\(|\).*$)/g, ''),
      amount,
      category,
      date: $date.slice(0, 10),
      currency,
      type: 'booked'
    }
  })

  const pendingTransactions = data.transactions.pending.map(transaction => {
    const {
      amount,
      bookingDate: { $date },
      currency,
      category,
      description,
    } = transaction

    return {
      description: description.replace(/(^.*\(|\).*$)/g, ''),
      amount,
      category,
      date: $date.slice(0, 10),
      currency,
      type: 'pending'
    }
  })

  return [...bookedTransactions, ...pendingTransactions]
}

export const transformRawTransactionsData = (data) => {

  const bookedTransactions = data.transactions.booked.map(transaction => {
    const {
      amount,
      category,
      bookingDate: { $date },
      currency,
      description,
    } = transaction

    return {
      description,
      amount,
      category,
      date: $date.slice(0, 10),
      currency,
      type: 'booked'
    }
  })

  const pendingTransactions = data.transactions.pending.map(transaction => {
    const {
      amount,
      bookingDate: { $date },
      currency,
      category,
      description,
    } = transaction

    return {
      description,
      amount,
      category,
      date: $date.slice(0, 10),
      currency,
      type: 'pending'
    }
  })

  return [...bookedTransactions, ...pendingTransactions]
}

export const transactionsExpandCategories = [
  {
    title: "Description",
    field: "description",
  },
  {
    title: "Amount",
    field: "amount",
  },
  {
    title: "Currency",
    field: "currency",
  },
  {
    title: "Account Type",
    field: "accountType",
  },
  {
    title: "Account",
    field: "account",
  },
  {
    title: "Transaction Type",
    field: "transactionType",
  },
  {
    title: "type",
    field: "type",
  },
]

export const transactionsUncategorizedCategories = [
  {
    title: "Description",
    field: "description",
  },
  {
    title: "Amount",
    field: "amount",
  },
  {
    title: "Date",
    field: "date",
  },
  {
    title: "Currency",
    field: "currency",
  },
  {
    title: "Account Type",
    field: "accountType",
  },
  {
    title: "Account",
    field: "account",
  },
  {
    title: "Transaction Type",
    field: "transactionType",
  },
  {
    title: "type",
    field: "type",
  },
  {
    title: "Category",
    field: "category",
  },
]

export const transformTransactionsExpandData = (data) => {

  const bookedTransactions = data.transactions.booked.map(transaction => {
    const {
      amount,
      bookingDate: { $date },
      currency,
      description,
      accountType,
      account,
      transactionType,
    } = transaction

    return {
      description,
      amount,
      date: $date.slice(0, 10),
      currency,
      accountType,
      account: account.slice(0, 10),
      transactionType,
      type: 'booked'
    }
  })

  const pendingTransactions = data.transactions.pending.map(transaction => {
    const {
      amount,
      bookingDate: { $date },
      currency,
      description,
      accountType,
      account,
      transactionType,
    } = transaction

    return {
      description,
      amount,
      date: $date.slice(0, 10),
      currency,
      accountType,
      account: account.slice(0, 10),
      transactionType,
      type: 'pending'
    }
  })

  return [...bookedTransactions, ...pendingTransactions]
}

export const transactionsRefreshCategories = [
  {
    title: "Status",
    field: "status",
  },
  {
    title: "Message",
    field: "statusMsg",
  },
]

export const loansEventsRefreshCategories = [
  {
    title: "Title",
    field: "title",
  },
  {
    title: "Type",
    field: "type",
  },
  {
    title: "Balance",
    field: "balance",
  },
  {
    title: "Interest",
    field: "interest",
  },
  {
    title: "Interest Rate Change",
    field: "interestRateChange",
  },
  {
    title: "Loan Type",
    field: "loanType",
  },
  {
    title: "Account Id",
    field: "accountId",
  },
  {
    title: "Next Day Of Terms Change",
    field: "nextDayOfTermsChange",
  },
]

export const loansApplicationCategories = [
  {
    title: "Requested Loan",
    field: "requestedLoan",
  },
  {
    title: "Refund Period",
    field: "refundPeriod",
  },
  {
    title: "Approved Loan",
    field: "approvedLoan",
  },
  {
    title: "Repayment Interest",
    field: "repaymentInterest",
  },
  {
    title: "Loan Score",
    field: "ficoScore",
  },
]

export const transformLoanApplicationData = (data) => {
  const { loanApplication } = data;

  return [loanApplication]
}

export const loansRefreshCategories = [
  {
    title: "Amortized",
    field: "amortized",
  },
  {
    title: "balance",
    field: "balance",
  },
  {
    title: "Initial Balance",
    field: "initialBalance",
  },
  {
    title: "Initial Date",
    field: "initialDate",
  },
  {
    title: "name",
    field: "name",
  },
  {
    title: "Provider Name",
    field: "providerName",
  },
  {
    title: "Type",
    field: "type",
  },
]

export const webhooksRefreshCategories = [
  {
    title: "User ID",
    field: "userId",
  },
  {
    title: "Events",
    field: "events",
  },
  {
    title: "URL",
    field: "url",
  },
]


export const paymentRefreshCategories = [
  {
    title: "Amount",
    field: "amount",
  },
  {
    title: "Currency",
    field: "currency",
  },
  {
    title: "Account Number",
    field: "accountNumber",
  },
  {
    title: "Execution Date",
    field: "executionDate",
  },
  {
    title: "Market",
    field: "market",
  },
  {
    title: "Payment Scheme",
    field: "paymentScheme",
  },
  {
    title: "Recipient Name",
    field: "recipientName",
  },
  {
    title: "Source Message",
    field: "sourceMessage",
  },
]

export const transactionsCategoryRefreshCategories = [
  {
    title: "Accounts",
    field: "accounts",
  },
  {
    title: "Categories",
    field: "categories",
  },
  {
    title: "Start Date",
    field: "startDate",
  },
  {
    title: "End Date",
    field: "endDate",
  },
  {
    title: "Query",
    field: "queryString",
  },
  {
    title: "Min Amount",
    field: "minAmount",
  },
  {
    title: "Max Amount",
    field: "maxAmount",
  },
]

export const transactionsSimilarRefreshCategories = [
  {
    title: "Amount",
    field: "amount",
  },
  {
    title: "Category Type",
    field: "categoryType",
  },
  {
    title: "Currency",
    field: "currencyCode",
  },
  {
    title: "Date",
    field: "date",
  },
  {
    title: "Description",
    field: "description",
  },
  {
    title: "Original Amount",
    field: "originalAmount",
  },
]

export const activityRefreshCategories = [
  {
    title: "Title",
    field: "title",
  },
  {
    title: "Importance",
    field: "importance",
  },
  {
    title: "Message",
    field: "message",
  },
  {
    title: "Date",
    field: "date",
  },
]

export const onSuccessRefreshCategories = [
  {
    title: "Status",
    field: "status",
  },
  {
    title: "Status Code",
    field: "statusCode",
  },
]

export const insightsRefreshCategories = [
  {
    title: "Insight Type",
    field: "type",
  },
  {
    title: "Title",
    field: "title",
  },
  {
    title: "Action Taken",
    field: "action",
  },
  {
    title: "Date Archived",
    field: "dateArchived",
  },
]

export const insightsCategories = [
  {
    title: "Insight Type",
    field: "type",
  },
  {
    title: "Title",
    field: "title",
  },
  {
    title: "Possible Action",
    field: "action",
  },
]


export const transformGenericData = (data) => data

export const transformTransactionsRefreshData = (data) => {

  return [
    {
      status: 202,
      statusMsg: 'Accepted'
    }
  ]
}

export const accountCategories = [
  {
    title: "ID",
    field: "psuId",
  },
  {
    title: "Valid Until",
    field: "validUntil",
  },
  {
    title: "Iban",
    field: "iban",
  },
  {
    title: "Status",
    field: "status",
  },

]

export const transformAccountData = (data) => {
  return data.map(accounts => {
    const {
      psuId,
      validUntil: { $date },
      iban,
      status,
    } = accounts
    return {
      psuId,
      validUntil: $date,
      iban,
      status,
    }
  })
}

export const balanceCategories = [
  {
    title: "ID",
    field: "psuId",
  },
  {
    title: "Amount",
    field: "amount",
  },
  {
    title: "Currency",
    field: "currency",
  },
  {
    title: "Date",
    field: "date",
  },
  {
    title: "Credit Limit",
    field: "creditLimitIncluded",
  },

]

export const transformBalanceData = (data) => {
  const {
    psuId,
    balances,
  } = data[0]


  return balances.map(balance => {
    const {
      balanceAmount: {
        amount,
        currency,
      },
      referenceDate: { $date },
    } = balance

    return {
      psuId,
      amount,
      currency,
      date: $date,
      creditLimitIncluded: creditLimits[Math.floor(Math.random() * creditLimits.length)],
    }
  })
}

export const authCategories = [
  {
    title: "Access Token",
    field: "accessToken",
  },
  {
    title: "Expires In",
    field: "expiresIn",
  },
]

export const transformAuthData = (data) => {
  return [data]

}

export const exampleCategories = [
  {
    title: "Name",
    field: "name",
  },
  {
    title: "Balance",
    field: "balance",
  },
  {
    title: "Account",
    field: "account",
  },
  {
    title: "Routing",
    field: "routing",
  },
]




export const transformExampleData = (data) => data.map(e => ({
  name: e.name,
  balance: formatCurrency(e.balance, e.currency),
  account: e.account,
  routing: e.routing,
}))

/*
[
  {
    "name": "gabi",
    "balance": "1213133.12",
    "currency": "EURO",
    "account": "123321",
    "routing": "something"
  },
  {
    "name": "mair",
    "balance": "13.12",
    "currency": "EURO",
    "account": "123344",
    "routing": "something22"
  },
  {
    "name": "shahar",
    "balance": "0.12",
    "currency": "NIS",
    "account": "222222",
    "routing": "omg"
  }
]
*/