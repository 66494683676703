import React from "react"

import "./index.css"

const Header = () => {
  return (
    <div className="grid">
      <h3 className="title">Open-Finance Catalog</h3>
      <>
        <h4 className="subtitle">
          Congrats! By linking an account, you have created an Consent.
        </h4>
        <div className="itemAccessContainer">
          <p className="itemAccessRow">
            <span className="idName">consent_id</span>
            <span className="tokenText">{"6ce0e6b0-3c7e-4b65-b868-d2e8274aee46"}</span>
          </p>

          <p className="itemAccessRow">
            <span className="idName">access_token</span>
            <span className="tokenText">{"PyIKjXmkHia4u0ozp5Hp7mZ-nCsyc23ICnFpt52Pe4Pj"}</span>
          </p>
        </div>
        <p className="requests">
          Now that you have an access_token, you can make all of the
          following requests:
        </p>
      </>
    </div>
  )
}

Header.displayName = "Header"

export default Header
