import React from "react"
import { makeStyles } from '@material-ui/core/styles'

const COLORS = {
  'GET': '#16ca94',
  'POST': '#fca828',
  'PUT': '#6e32e5',
  'DELETE': '#f3455c',
  'error': '#f3455c',
}

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'right',
    marginTop: theme.spacing(2),
  },
  wrapper: {
    border: ({ method }) => `1px solid ${COLORS[method]}`,
    minWidth: 74,
    borderRadius: '2px',
    display: 'inline-flex',
    flexDirection: 'row',
    cursor: 'default',
    height: '36px',
    outline: 'none',
    padding: '0',
    fontSize: '14px',
    fontColor: '#333333',
    fontFamily: '"Open Sans", sans-serif',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    verticalAlign: 'middle',
    textDecoration: 'none',
    justifyContent: ' center',
  },
}))

export default function Note(props) {
  const {
    className,
  } = props
  const classes = useStyles(props)
  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div className={className}>
          {props.children}
        </div>
      </div>
    </div>
  )
}