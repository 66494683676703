import React, { useState } from 'react'
import SearchBar from '../Common/SearchBar'
import BanksList from './BanksList'

export default function ChooseBank(props) {
  const {
    banks = [],
    language = { isRtl: false, id: 'EN' },
  } = props

  const [value, setValue] = useState('')

  const filteredBanks = Object.values(banks).filter(({ bankId }) => bankId.toLowerCase().includes(value))
  const onClick = (_, id) => {
    console.log(id)
    window.location.assign(`https://link.tink.com/1.0/transactions/connect-accounts/?client_id=947e91ab1c4a400d80bc12ec8a205c72&redirect_uri=https%3A%2F%2Fconsole.tink.com%2Fcallback&market=${id}&locale=en_US`)
  }

  return (
    <>
      <SearchBar
        value={value}
        onChange={({ target: { value: val } }) => {
          setValue(val)
        }}
        isRtl={language.isRtl}
        label="Search Market"
      />
      <BanksList banks={filteredBanks} onClick={onClick} />
    </>
  )
}